import BulkOperations from "../../../../../frappe/frappe/public/js/frappe/list/bulk_operations";

frappe.views.ListView = class ListView extends frappe.views.ListView {
	get_workflow_action_menu_items() {
		return []
	}

	get_actions_menu_items() {
		const actions_menu_items = super.get_actions_menu_items();
		const bulk_operations = new BulkOperations({ doctype: this.doctype });

		// Remove Edit from actions menu (disable bulk edit)
		const edit_label = __("Edit", null, "Button in list view actions menu");
		const edit_index = actions_menu_items.findIndex(item => item.label === edit_label);
		if (edit_index > -1) {
			actions_menu_items.splice(edit_index, 1);
		}

		const index = actions_menu_items.findIndex(item => item.label === __("Clear Assignment", null, "Button in list view actions menu"));
		if (index > -1) {
			actions_menu_items[index] = this.bulk_assignment_clear(bulk_operations);
		}

		return actions_menu_items;
	}

	bulk_assignment_clear(bulk_operations) {
		return {
			label: __("Clear Assignment", null, "Button in list view actions menu"),
			action: () => {
				frappe.confirm(
					__("Are you sure you want to clear the assignments?"),
					() => {
						this.disable_list_update = true;
						bulk_operations.clear_assignment(this.get_checked_items(true), () => {
							this.disable_list_update = false;
							this.clear_checked_items();
							this.refresh();
						});
					},
					() => {
						this.clear_checked_items();
						this.refresh();
					}
				);
			},
			standard: true,
		};
	};

	get_filter_value(fieldname) {
		const filter = this.get_filters_for_args().filter((f) => f[1] == fieldname)[0];
		if (!filter) return;

		const filterValue = filter[3];
		return (
			{
				like: typeof filterValue === 'string' ? filterValue.replace(/^%?|%$/g, "") : filterValue,
				"not set": null,
			}[filter[2]] || filterValue
		);
	}
}
